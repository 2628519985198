* {
    scroll-behavior: smooth;
}
.slide .slick-prev {
    display: none !important;
}
.slide .slick-next {
    display: none !important;
}
.img-responsive {
    width: 100%;
    height: auto;
}
.top-ten .slick-slide {
    padding: 0px 10px;
}
.locks .slick-slide {
    padding: 0px 10px;
}
.map {
    border: 0;
}
.iframe-detail {
    border: 0;
}
.slide_movil {
    display: none !important;
}
@media only screen and (max-width: 600px) {
    .slide_movil {
        display: block !important;
        width: 100%;
        height: auto;
    }
    .slide_desktop {
        display: none !important;
    }
}